<template>
    <div id="container">
        <el-main>
            <!-- 面包屑导航区域 -->
            <bread-crumb
                    :type="2"
                    :step="examInfo.exam_type==7?8:7"
            ></bread-crumb>

            <!-- 主干区域 -->
            <div class="content">
                <div class="h_title" v-if="examInfo.exam_type==7">
                    查看预定信息<span>关注“THUSSAT服务”服务号，第一时间接收考试提醒。</span>
                </div>
                <div class="h_title" v-else>
                    查看报名信息<span>关注“THUSSAT服务”服务号，第一时间接收考试提醒。</span>
                </div>

                <!-- 卡片区域 -->
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <div class="fl">
                            <div class="numner">
                                <span v-if="examInfo.exam_type==7">预定编号：</span>
                                <span v-else>报名编号：</span>
                                {{ examInfo.order_no ? examInfo.order_no : "-" }}
                            </div>
                        </div>

                        <div class="status">
                            <span v-if="examInfo.status == 3 && examInfo.exam_type != 7"
                                  style="color: #ee7602">报名中</span>
                            <span v-if="examInfo.status == 3 && examInfo.exam_type == 7"
                                  style="color: #ee7602">预定中</span>
                            <span v-if="examInfo.status == 2" style="color: #56327a">待付款</span>
                            <span v-if="examInfo.status == 1 && examInfo.exam_type != 7">已报名</span>
                            <span v-if="examInfo.status == 1 && examInfo.exam_type == 7">已预定</span>
                            <span v-if="examInfo.status == 0">取消报名</span>
                        </div>
                    </div>
                    <div class="text examInfo">
                        <div class="h_info"
                             v-if="examInfo.status == 2 && (examInfo.exam_type == 4 || examInfo.exam_type == 7)">
                            注：考试名额已锁定，等待用户支付，剩余<span>{{ remainTime }}</span
                        >自动关闭。请尽快完成支付。
                        </div>
                        <div class="h_info" v-if="examInfo.status == 0 && examInfo.exam_type != 7">
                            注：报名费用将于3-5个工作日沿原路返回至您的账户，请关注账户信息，如果有疑问，请联系考务：service@thussat.com。
                        </div>
                        <div class="info_txt">
                            姓名：{{
                            studentInfo.student_name ? studentInfo.student_name : "-"
                            }}
                        </div>
                        <div class="info_txt">
                            证件号码：{{ studentInfo.id_card ? studentInfo.id_card : "-" }}
                        </div>
                        <div class="info_txt">
                            考试名称：{{ examInfo.exam_name ? examInfo.exam_name : "-" }}
                        </div>
                        <div class="info_txt">
                            考试时间：{{
                            examInfo.execute_time ? examInfo.execute_time : "-"
                            }}
                        </div>
                        <div class="info_txt" v-if="(examInfo.exam_type == 4 || examInfo.exam_type == 7)">
                            考试科目：{{ examInfo.subject ? examInfo.subject : "-" }}
                        </div>
                        <div class="info_txt" v-if="examInfo.exam_type == 4">
                            预定考点：{{ examInfo.city ? examInfo.city : "-" }}
                        </div>
                        <div class="info_txt" v-if="examInfo.exam_type == 7">
                            预定考点省份：{{ examInfo.province ? examInfo.province : "-" }}
                        </div>

                        <!-- 价格 -->
                        <div class="price">
                            <div v-if="examInfo.exam_type && examInfo.exam_type != 7">
                                报名费用：<span
                            >￥{{ (examInfo.amount||examInfo.amount===0||examInfo.amount===0) ? examInfo.amount : "-" }}</span
                            >
                            </div>
                        </div>
                        <!-- 底部按钮 -->
                        <div class="foot-btn">
                            <el-button
                                    v-if="examInfo.status == 2 && (examInfo.exam_type == 4 || examInfo.exam_type == 7)"
                                    class="btn_1"
                                    @click="cancelReserve()"
                            >取消预定
                            </el-button>
                            <el-button
                                    v-if="examInfo.status == 2 && examInfo.amount>0"
                                    class="btn_2"
                                    @click="confirmSignUp()"
                            >支付费用
                            </el-button>
                            <el-button
                                    v-if="examInfo.status == 2 && examInfo.amount==0"
                                    class="btn_2"
                                    @click="confirmSignUp()"
                            >
                                <span v-if="examInfo.exam_type == 7">进入考试</span>
                                <span v-else>立即报名</span>
                            </el-button>
                            <el-button
                                    v-if="examInfo.order_info&&examInfo.order_info.show_postpone_status==1"
                                    class="btn_2"
                                    @click="postponeHandle()"
                            >申请延期
                            </el-button>
                            <!--v-if="examInfo.order_info&&examInfo.order_info.show_refund_status == 1"-->
                            <el-button
                                    v-if="examInfo.status == 1 && examInfo.can_cancel == 1 && examInfo.exam_type != 7"
                                    class="btn_1"
                                    @click="cancelSignUp()"
                            >取消报名
                            </el-button>
                            <el-button
                                    v-if="examInfo.status == 0"
                                    class="btn_2"
                                    @click="toSignUp()"
                                    :disabled="isDisable"
                            >
                                <span v-if="examInfo.exam_type == 7">进入考试</span>
                                <span v-else>重新报名</span>
                            </el-button>
                            <el-button
                                    v-if="examInfo.status == 3"
                                    class="btn_2"
                                    @click="toSignUp()"
                                    :disabled="isDisable"
                            >
                                <span v-if="examInfo.exam_type == 7">进入考试</span>
                                <span v-else>继续报名</span>
                            </el-button>
                            <el-button
                                    v-if="examInfo.status == 1 && !isHasYQMCode"
                                    class="btn_2"
                                    @click="toUpdateCode()"
                                    :disabled="isDisable"
                            >
                                <span>补填邀请码</span>
                            </el-button>
                        </div>
                    </div>
                </el-card>
            </div>

            <!--<div class="content" v-if="examInfo.order_status==6 && applyInfo.time_out!=1">
                <div class="report_info">
                    <img src="@/assets/images/report.png"></img>
                    考生请注意
                </div>
                <div class="report_btn" v-if="applyInfo.status==-1">
                    <span>处于中高风险地区所在城市或在考试开始前14天有中高风险地区所在城市旅居史的考生，需在7月24日24:00前报备。</span>
                    <el-button :class="applyInfo.time_out==0?'btn_2':'btn_1'" @click="toReport">立即报备</el-button>
                </div>
                <div class="report_btn" v-if="applyInfo.status==2">
                    <span>你的报备已提交成功，请等待考务人员审核，审核结果将在3-5个工作日内告知，请关注网站及手机短信。</span>
                    <span style="color: #563279;">审核中</span>
                </div>
                <div class="report_btn" v-if="applyInfo.status==1">
                    <span>你的报备审核已通过，请于2022-8-4 10:00 至2022-8-6 24:00 登录本网站下载准考证并了解后续考试安排。</span>
                    <span style="color: #563279;">已通过</span>
                </div>
                <div class="report_btn" v-if="applyInfo.status==0">
                    <span>你的报备审核不通过，理由是：{{applyInfo.reject_reason}}。可在7月24日24:00前重新报备。</span>
                    <el-button :class="applyInfo.time_out==0?'btn_2':'btn_1'" @click="toReport">重新报备</el-button>
                </div>
            </div>-->
        </el-main>

        <!-- 取消预定弹框 -->
        <cancel-reserve
                :examuuid="examuuid"
                :reserveDialogFu="reserveDialogFu"
                @closeReserveDialog="closeReserveDialog"
        ></cancel-reserve>
        <!-- 确认报名弹框（支付） -->
        <confirm-sign-up
                :examuuid="examuuid"
                :confirmSignUpDialogFu="confirmSignUpDialogFu"
                @closeCFSUDialog="closeCFSUDialog"
        ></confirm-sign-up>
        <!-- 取消报名弹框 -->
        <cancel-sign-up
                :examuuid="examuuid"
                :price="price"
                :cancelSignUpDialogFu="cancelSignUpDialogFu"
                @closeCSUDialog="closeCSUDialog"
        ></cancel-sign-up>
        <!-- 补填邀请码弹框 -->
        <update-code
                :examuuid="examuuid"
                :updateCodeDialogFu="updateCodeDialogFu"
                @closeUpdateCodeDialog="closeUpdateCodeDialog"
        ></update-code>
        <!--相关说明弹框-->
        <agree-box
                :agreeBoxDialogFu="agreeBoxDialogFu"
                @closeABDialog="closeABDialog"
        ></agree-box>
        <!--message弹框-->
        <message-box :messDialog="messDialog" @closeMessage="closeMessage"></message-box>
    </div>
</template>

<script>
    import breadCrumb from "c/breadcrumb";
    import CancelReserve from "c/index/signUp/CancelReserve";
    import ConfirmSignUp from "c/index/signUp/ConfirmSignUp";
    import CancelSignUp from "c/index/signUp/CancelSignUp.vue";
    import UpdateCode from "c/index/signUp/UpdateCode.vue";
    import AgreeBox from "c/index/signUp/AgreeBox";
    import MessageBox from "c/index/signUp/MessageBox";
    import {getOrderInfo, delayOrder} from "r/index/order.js";
    import {getHomeApply, cancelInviteCode} from "r/index/signUp";
    import {signUpInitReq} from "common/request";
    import {djTime} from "s/time.js";

    export default {
        name: "ViewInfo",
        data() {
            return {
                isDisable: false, // 禁用 false-不禁用，true-禁用
                examInfo: {},
                studentInfo: {},
                remainTime: "", // 剩余时间
                timer: false, // 倒计时计时器
                reserveDialogFu: false, // 控制取消预定弹框的显示与隐藏
                orderno: "", // 订单号
                examuuid: "", // 绑定考试id
                price: "", // 绑定订单价格
                confirmSignUpDialogFu: false, // 控制确认报名弹框的显示与隐藏
                cancelSignUpDialogFu: false, // 控制取消报名弹框显示与隐藏
                agreeBoxDialogFu: false, // 控制相关说明弹框的显示与隐藏
                updateCodeDialogFu: false, // 控制补填邀请码弹框显示与隐藏
                messDialog: false,// 控制message弹框显示与隐藏
                applyInfo: {},// 报备信息(报备状态status: -2-报备按钮置灰 -1-未申请 0-未通过 1-已通过 2-审核中)(时间限制time_out 1-时间未到 0-到时间了 2-过了时间)
                isHasYQMCode: true,// 是否已经绑定邀请码
            };
        },
        created() {
            if (!window.localStorage.getItem('exam_uuid') || window.localStorage.getItem('exam_uuid') == '' || window.localStorage.getItem('exam_uuid') == 'null') {
                return this.$router.replace({name: 'examlist'})
            }
            this.examuuid = window.localStorage.getItem("exam_uuid");
            this.orderno = window.localStorage.getItem("order_no");
            this.getOrderInfo();
        },
        methods: {
            //判断是否已绑定邀请码
            async hasYQM() {
                const data = {
                    exam_uuid: this.examuuid,
                    is_check: 1,
                };
                const {data: res} = await cancelInviteCode(data);
                if (res.code == 200) {
                    let code = res.data.invitation_code; //回显的邀请码
                    if (code) {
                        return this.isHasYQMCode = true;
                    }
                    this.isHasYQMCode = false;
                }
            },
            // 立即报备/重新报备
            toReport() {
                if (this.applyInfo.time_out != 0) {
                    return this.$message.info('报备时间已截止');
                }
                this.$router.push('/signUp/reportInfo');
            },
            // 获取当前报备状态
            getHomeApply() {
                let obj = {
                    exam_uuid: window.localStorage.getItem("exam_uuid"),
                }
                getHomeApply(obj).then(res => {
                    let resData = res.data;
                    if (resData.code != 200) {
                        return this.$message.error(resData.message)
                    }
                    this.applyInfo = resData.data;
                }).catch(err => {
                    console.log(err)
                })
            },
            // 子组件触发，关闭message弹框
            closeMessage(val) {
                this.messDialog = val;
                return this.$router.replace({name: "OrderList"});
            },
            // 重新报名
            async toSignUp() {
                if (this.isDisable) {
                    return false;
                }
                this.isDisable = true;
                let examuuid = window.localStorage.getItem("exam_uuid")
                await signUpInitReq(examuuid);
                this.isDisable = false;
            },
            // 子组件触发，关闭相关说明弹框
            closeABDialog(val, type) {
                this.agreeBoxDialogFu = val;
                if (type) {
                    this.confirmSignUpDialogFu = true;
                }
            },
            // 补填邀请码子组件回调
            closeUpdateCodeDialog(val, type) {
                this.updateCodeDialogFu = val;
                if (type) {
                    this.isHasYQMCode = true;
                }
            },
            // 取消报名子组件回调
            closeCSUDialog(val, type) {
                this.cancelSignUpDialogFu = val;
                if (type) {
                    this.$router.replace({name: "cancelspplysuccess"});
                }
            },
            // 子组件触发，关闭确认报名弹框
            closeCFSUDialog(val, type) {
                this.confirmSignUpDialogFu = val;
                if (type) {
                    clearInterval(this.timer);
                }
            },
            // 子组件触发，关闭取消预定弹框
            closeReserveDialog(val, type) {
                this.reserveDialogFu = val;
                if (type) {
                    clearInterval(this.timer);
                    this.$router.replace({name: "SignUpLists"});
                }
            },
            // 申请延期按钮
            postponeHandle() {
                if (this.examInfo.order_info && this.examInfo.order_info.postpone_status == 1) {
                    return this.$message.info('您已提交延期申请!');
                }
                this.$confirm('是否确认申请考试延期至8月TACA综合测试?', '申请延期', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: ''
                }).then(async () => {
                    let {data: res} = await delayOrder({
                        exam_uuid: this.examuuid,
                        delay: 1,
                    });
                    console.log(res);
                    if (res.code !== 200) return this.$message.error(res.message);
                    this.$message.success('考试延期申请成功！');
                    await this.getOrderInfo();
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });
                });
            },
            // 补填邀请码按钮
            toUpdateCode() {
                this.updateCodeDialogFu = true;
            },
            // 取消报名按钮
            cancelSignUp() {
                this.price = this.examInfo.amount;
                this.cancelSignUpDialogFu = true;
            },
            // 确认报名
            confirmSignUp() {
                window.localStorage.setItem("exam_type", this.examInfo.exam_type);
                this.agreeBoxDialogFu = true;
            },
            // 取消预定
            cancelReserve() {
                this.reserveDialogFu = true;
            },

            // 获取订单信息
            async getOrderInfo() {
                let {data: res} = await getOrderInfo({
                    exam_uuid: this.examuuid,
                    order_no: this.orderno,
                });
                console.log(res);
                if (res.code !== 200) return this.$message.error(res.message);
                this.examInfo = res.info;
                this.studentInfo = res.student;
                window.localStorage.setItem("exam_type", this.examInfo.exam_type);

                if (res.info.status == 2 && (res.info.exam_type == 4 || res.info.exam_type == 7)) {
                    this.remainTime = djTime(res.info.end_time);
                    this.timer = setInterval(() => {
                        if (this.examInfo.end_time > 0) {
                            this.examInfo.end_time -= 1;
                            return this.remainTime = djTime(this.examInfo.end_time);
                        }
                        clearInterval(this.timer);
                        this.messDialog = true;
                    }, 1000);
                } else if (res.info.order_status == 6) {
                    // this.getHomeApply();
                    await this.hasYQM();
                }
            },
        },
        destroyed() {
            clearInterval(this.timer);
        },
        components: {
            breadCrumb,
            CancelReserve,
            ConfirmSignUp,
            CancelSignUp,
            UpdateCode,
            AgreeBox,
            MessageBox
        },
    };
</script>

<style scoped lang="scss">
    // 面包屑样式
    @import "a/scss/beradcrumb";
    // 表格样式
    @import "a/scss/table";

    #container {
        width: 100%;
        min-height: 80%;
        margin: 0 auto;
        overflow: auto;

        .el-main {
            max-width: 1300px;
            margin: 6px auto 0;
            padding: 0 50px;

            .content {
                background-color: #ffffff;
                padding: 37px 60px 0;
                margin: 20px 0;

                &:last-child {
                    padding: 27px 60px;
                }

                .h_title {
                    font-size: 24px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #333333;

                    span {
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        margin-left: 12px;
                    }
                }

                ::v-deep .el-card {
                    border-radius: 4px;
                    // border: 1px solid #e0e0e0;
                    border: 0;
                    box-shadow: 0 0 0;
                    margin-top: 15px;

                    .el-card__header {
                        border-bottom: 0;
                        padding: 0;
                        // padding: 18px 30px 18px 40px;
                        padding: 15px 0;
                        height: auto;
                        border-bottom: 1px solid #e0e0e0;

                        .clearfix {
                            width: 100%;
                            display: flex;
                            flex-flow: row;

                            .fl {
                                flex: 5;

                                .numner {
                                    float: left;
                                    height: 26px;
                                    line-height: 26px;
                                    font-size: 18px;
                                    font-family: PingFang SC;
                                    font-weight: 400;
                                    color: #333333;
                                }
                            }

                            .status {
                                flex: 1;
                                font-size: 18px;
                                font-family: PingFang SC;
                                font-weight: 300;
                                color: #999999;
                                text-align: right;
                            }
                        }
                    }

                    .el-card__body {
                        padding: 34px 0 0;

                        .el-table {
                            &::before {
                                height: 0;
                            }
                        }

                        .h_info {
                            font-size: 14px;
                            font-family: PingFang SC;
                            font-weight: 400;
                            color: #333333;
                            line-height: 20px;
                            margin-bottom: 5px;

                            span {
                                font-size: 30px;
                                color: #ee7602;
                                font-family: PingFangSC-Regular;
                            }
                        }

                        .info_txt {
                            font-size: 14px;
                            font-family: PingFang SC;
                            font-weight: 400;
                            color: #333333;
                            margin: 24px 0;
                        }

                        // 价格
                        .price {
                            text-align: right;
                            font-size: 14px;
                            font-family: PingFang SC;
                            font-weight: 400;
                            color: #333333;
                            // margin: 158px 0 60px 0;
                            margin: 58px 0 30px 0;

                            span {
                                font-size: 24px;
                                font-family: PingFang SC;
                                font-weight: 400;
                                color: #563279;
                            }
                        }

                        // 底部按钮
                        .foot-btn {
                            height: 74px;
                            padding: 19px 0;
                            border-top: 1px solid #e0e0e0;
                            text-align: right;

                            .el-button {
                                width: 120px;
                                height: 36px;
                                border: 0;
                                background-color: #cccccc;
                                margin: 0 0 0 20px;
                                border-radius: 4px;
                                padding: 0;
                                color: #ffffff;
                            }

                            .btn_1 {
                                background-color: #cccccc;
                            }

                            .btn_2 {
                                background-color: #563279;
                            }
                        }
                    }
                }

                .report_info {
                    height: 34px;
                    font-size: 18px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 34px;

                    img {
                        vertical-align: center;
                        margin-top: -5px;
                    }
                }

                .report_btn {
                    display: flex;
                    flex-flow: row;

                    > span:nth-child(1) {
                        flex: 1;
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #EE7602;
                        line-height: 36px;
                    }

                    .el-button {
                        width: 120px;
                        height: 36px;
                        border: 0;
                        background-color: #cccccc;
                        border-radius: 4px;
                        padding: 0;
                        margin: 0;
                        color: #ffffff;
                    }

                    .btn_1 {
                        background-color: #cccccc;
                    }

                    .btn_2 {
                        background-color: #563279;
                    }
                }
            }
        }
    }
</style>
