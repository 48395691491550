<template>
  <el-dialog
    title="取消报名申请"
    :visible.sync="dialogVisible"
    width="520px"
    top="0"
    :before-close="cancelBtn"
    :close-on-click-modal="false"
    @open="openHandle"
  >
    <div>
      <div class="info_2">理由：</div>
      <el-input
        placeholder="取消报名理由"
        v-model.trim="inputVal"
        @focus="onFocus"
      ></el-input>
      <div class="isinfo">
        <span v-if="isShowInfo">请输入取消报名理由！</span>
      </div>
    </div>
    <div class="info_2" v-if="price>0">退款金额：{{ price }}元</div>
    <!-- <div class="info_2">请填写取消报名的理由并点【确认】提交，THUSSAT项目组将在3个工作日内进行处理。</div> -->
    <div class="info_2">{{enroll_end_time}}前可取消报名，<span v-if="price>0">费用将于3-5个工作日原路返还。</span>{{enroll_end_time}}后无法取消报名。</div>

    <span slot="footer" class="dialog-footer">
      <el-button class="cancel" @click="cancelBtn()">取 消</el-button>
      <el-button class="confirm" @click="confirm()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { cancelOrder } from "r/index/order.js";
import {formatDate2} from "../../../store/time";
export default {
  name: "CancelSignUp",
  props: {
    cancelSignUpDialogFu: Boolean,
    examuuid: String,
    price: [Number, String],
  },
  data() {
    return {
      dialogVisible: this.cancelSignUpDialogFu,
      inputVal: "", // 取消报名理由
      isShowInfo: false,
      enroll_end_time: '',
    };
  },
  methods: {
    openHandle(){
      let enroll_end_time = window.localStorage.getItem("enroll_end_time");
      this.enroll_end_time = formatDate2(enroll_end_time*1000);
    },
    async confirm() {
      if (this.inputVal == "") {
        return (this.isShowInfo = true);
      }
      let { data: res } = await cancelOrder({
        exam_uuid: this.examuuid,
        cancel_reason: this.inputVal,
      });
      console.log(res);
      if (res.code !== 200) return this.$message.error(res.message);

      this.isShowInfo = false;
      this.dialogVisible = false;
      this.$emit("closeCSUDialog", this.dialogVisible, true);
    },
    onFocus() {
      this.isShowInfo = false;
    },
    // 取消,关闭弹框，修改父组件的值
    cancelBtn() {
      this.isShowInfo = false;
      this.dialogVisible = false;
      this.$emit("closeCSUDialog", this.dialogVisible, false);
    },
  },
  watch: {
    cancelSignUpDialogFu() {
      this.dialogVisible = this.cancelSignUpDialogFu;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/dialog";
.info_2 {
  margin: 10px 0;
}
.isinfo {
  font-size: 12px;
  color: #e0823d;
  height: 20px;
  line-height: 20px;
}
</style>
