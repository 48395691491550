<template>
    <el-dialog
            title="补填邀请码"
            :visible.sync="dialogVisible"
            width="520px"
            top="0"
            @open="openHandle"
            :before-close="beforeClose"
            :close-on-click-modal="false"
    >
        <div style="width: 440px; display: flex; justify-content: space-between"
        >
            <el-input
                    maxlength="19"
                    :disabled="!inputFlag"
                    :autofocus="true"
                    ref="input0"
                    @input="changeValue1"
                    @focus="getFocus(0, $event)"
                    v-model="invatation1"
            >
            </el-input>
            <el-input
                    :disabled="!inputFlag"
                    maxlength="4"
                    ref="input1"
                    onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
                    @input="changeValue"
                    @focus="getFocus(1, $event)"
                    v-model="invatation2"
            >
            </el-input>
            <el-input
                    :disabled="!inputFlag"
                    max="4"
                    ref="input2"
                    onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
                    @input="changeValue"
                    @focus="getFocus(2, $event)"
                    v-model="invatation3"
            >
            </el-input>
            <el-input
                    :disabled="!inputFlag"
                    max="4"
                    ref="input3"
                    onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
                    @input="changeValue"
                    @focus="getFocus(3, $event)"
                    v-model="invatation4"
            >
            </el-input>
        </div>
        <div class="info_2 color_w">注意：考生在报名系统中完成缴费即视为报名成功。如需补填邀请码，请按照正确格式填写。</div>
        <span slot="footer" class="dialog-footer">
            <el-button class="cancel" @click="cancelBtn()">取 消</el-button>
            <el-button class="confirm" @click="confirm()">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {cancelInviteCode} from "r/index/signUp.js";
    import {updateInviteCode} from "r/index/order.js";

    export default {
        name: "UpdateCode",
        props: {
            updateCodeDialogFu: Boolean,
            examuuid: String,
        },
        data() {
            return {
                dialogVisible: this.updateCodeDialogFu,
                invatation1: "",
                invatation2: "",
                invatation3: "",
                invatation4: "",
                inputFlag: true,
                currentIndex: 0,
            };
        },
        methods: {
            openHandle() {
                // this.hasYQM()
            },
            //第一个输入框
            changeValue1(e) {
                if (e.length == 19 && e.indexOf("-") !== -1) {
                    //如果是复制过来的带'-'邀请码
                    let copyCode = e.split("-");
                    this.invatation1 = copyCode[0];
                    this.invatation2 = copyCode[1];
                    this.invatation3 = copyCode[2];
                    this.invatation4 = copyCode[3];
                    this.$refs.input3.focus();
                } else if (e.length > 4) {
                    this.changeValue(e.substring(0, 4));
                } else {
                    this.changeValue(e);
                }
            },
            changeValue(e) {
                if (e.length >= 4) {
                    switch (this.currentIndex) {
                        case 0:
                            if (this.invatation1.length >= 4) {
                                this.$refs.input1.focus();
                            }
                            break;
                        case 1:
                            if (this.invatation2.length >= 4) {
                                this.$refs.input2.focus();
                            }
                            break;
                        case 2:
                            if (this.invatation3.length >= 4) {
                                this.$refs.input3.focus();
                            }
                            break;
                        case 3:
                            if (this.invatation4.length >= 4) {
                                this.$refs.input3.focus();
                            }
                            break;
                        default:
                            break;
                    }
                } else if (e.length === 0) {
                    switch (this.currentIndex) {
                        case 0:
                            this.$refs.input0.focus();
                            break;
                        case 1:
                            this.$refs.input0.focus();
                            break;
                        case 2:
                            this.$refs.input1.focus();
                            break;
                        case 3:
                            this.$refs.input2.focus();
                            break;
                        default:
                            break;
                    }
                }
            },
            getFocus(index, e) {
                this.currentIndex = index;
            },
            //判断是否已绑定邀请码
            async hasYQM() {
                const data = {
                    exam_uuid: this.examuuid,
                    is_check: 1,
                };
                const {data: res} = await cancelInviteCode(data);
                if (res.code == 200) {
                    let code = res.data.invitation_code; //回显的邀请码
                    if (code) {
                        let copyCode = code.split("-");
                        this.invatation1 = copyCode[0];
                        this.invatation2 = copyCode[1];
                        this.invatation3 = copyCode[2];
                        this.invatation4 = copyCode[3];
                    }
                }
            },
            // 确定
            async confirm() {
                let code;
                if (
                    this.invatation1 == "" &&
                    this.invatation2 == "" &&
                    this.invatation3 == "" &&
                    this.invatation4 == ""
                ) {
                    this.$message.info('请填写邀请码')
                    return false;
                } else if (
                    this.invatation1.length < 4 ||
                    this.invatation2.length < 4 ||
                    this.invatation3.length < 4 ||
                    this.invatation4.length < 4
                ) {
                    this.$message.info('请填写正确的邀请码')
                    return false;
                } else {
                    code =
                        this.invatation1 +
                        "-" +
                        this.invatation2 +
                        "-" +
                        this.invatation3 +
                        "-" +
                        this.invatation4;
                }
                let {data: res} = await updateInviteCode({
                    exam_uuid: this.examuuid,
                    code: code,
                });
                console.log(res);
                if (res.code !== 200) return this.$message.error(res.message);
                this.$message.success(res.message)
                this.cancelBtn(true);
            },
            // 取消,关闭弹框，修改父组件的值
            cancelBtn(type = false) {
                this.invatation1 = "";
                this.invatation2 = "";
                this.invatation3 = "";
                this.invatation4 = "";
                this.dialogVisible = false;
                this.$emit("closeUpdateCodeDialog", this.dialogVisible, type);
            },
            // 弹框icon---X关闭
            beforeClose() {
                this.cancelBtn(false)
            },
        },
        watch: {
            updateCodeDialogFu() {
                this.dialogVisible = this.updateCodeDialogFu;
            },
            invatation1() {
                this.invatation1 = this.invatation1
                    .replace(/[\W]/g, "")
                    .toUpperCase()
                    .substring(0, 4);
            },
            invatation2() {
                this.invatation2 = this.invatation2
                    .replace(/[\W]/g, "")
                    .toUpperCase()
                    .substring(0, 4);
            },
            invatation3() {
                this.invatation3 = this.invatation3
                    .replace(/[\W]/g, "")
                    .toUpperCase()
                    .substring(0, 4);
            },
            invatation4() {
                this.invatation4 = this.invatation4
                    .replace(/[\W]/g, "")
                    .toUpperCase()
                    .substring(0, 4);
            },
        },
    };
</script>

<style scoped lang="scss">
    @import "../../../assets/scss/dialog";
    .info_2 {
        margin: 20px 0 10px;
    }
    .color_w{
        color: #F59A23 !important;
    }

    ::v-deep .el-input__inner {
        width: 100px;
        height: 40px;
        text-align: center;
        flex: 1;
        border-radius: 4px;
        border: 1px solid #e1e3e6;
    }
</style>
